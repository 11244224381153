import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => <SvgIcon {...props}>
  <svg viewBox="0 0 368.55 304.75">
    <path d="M361.05,299.75H20.24l52.93-95.41a60.57,60.57,0,0,1,94.68-14.52l23.64,22.47A43.64,43.64,0,0,0,257,206.05l.45-.63a16.62,16.62,0,0,1,27.38.49l43.65,65.85a7.5,7.5,0,1,0,12.5-8.29l-43.65-65.85a31.62,31.62,0,0,0-52.08-.93l-.45.63a28.64,28.64,0,0,1-43,4.1l-23.64-22.47A75.57,75.57,0,0,0,60.05,197.06L.94,303.61A7.5,7.5,0,0,0,7.5,314.75H361.05a7.5,7.5,0,0,0,0-15Z" transform="translate(0 -10)" />
    <g>
      <g>
        <path d="M218,49.4c-2.58-3.8-4.86-7.29-7-10.48a4.62,4.62,0,0,0-1.21-1.21,4,4,0,0,0-5.47,1.21c-2,3.19-4.25,6.53-7,10.48-12,17.62-29.92,44.19-29.92,59.38a40.06,40.06,0,0,0,11.85,28.4,40.57,40.57,0,0,0,28.4,11.69,40.45,40.45,0,0,0,40.25-40.25C247.94,93.44,230,67,218,49.4Zm12.61,82.16A32.12,32.12,0,0,1,207.7,141a32.76,32.76,0,0,1-22.93-9.42,32.12,32.12,0,0,1-9.42-22.93c0-12.76,17.16-38.12,28.55-55,1.37-2,2.58-3.95,3.8-5.62,1.21,1.67,2.43,3.64,3.8,5.62,11.39,17,28.55,42.22,28.55,55A32.76,32.76,0,0,1,230.63,131.56Z" transform="translate(0 -10)" />
        <path d="M231.24,105.89a3.89,3.89,0,0,0-4.1,3.64,19.55,19.55,0,0,1-2.89,9.26,18.46,18.46,0,0,1-7,6.68,4,4,0,0,0,3.95,7,26.32,26.32,0,0,0,9.72-9.42A25.84,25.84,0,0,0,234.88,110,3.89,3.89,0,0,0,231.24,105.89Z" transform="translate(0 -10)" />
      </g>
      <g>
        <path d="M283.57,19.48c-2-2.92-3.74-5.61-5.37-8.06a3.55,3.55,0,0,0-.93-.93,3,3,0,0,0-4.2.93c-1.52,2.45-3.27,5-5.37,8.06-9.23,13.55-23,34-23,45.67A30.81,30.81,0,0,0,253.79,87a31.2,31.2,0,0,0,21.84,9,31.11,31.11,0,0,0,31-31C306.58,53.35,292.8,33,283.57,19.48Zm9.69,63.19a24.7,24.7,0,0,1-17.64,7.24A25.19,25.19,0,0,1,258,82.67,24.7,24.7,0,0,1,250.75,65c0-9.81,13.2-29.32,22-42.28,1.05-1.52,2-3,2.92-4.32.93,1.28,1.87,2.8,2.92,4.32,8.76,13.08,22,32.47,22,42.28A25.19,25.19,0,0,1,293.26,82.67Z" transform="translate(0 -10)" />
        <path d="M293.73,62.93a3,3,0,0,0-3.15,2.8,15,15,0,0,1-2.22,7.12A14.19,14.19,0,0,1,283,78a3.09,3.09,0,0,0,3,5.37,20.24,20.24,0,0,0,7.48-7.24,19.88,19.88,0,0,0,3-10A3,3,0,0,0,293.73,62.93Z" transform="translate(0 -10)" />
      </g>
      <g>
        <path d="M280.79,121c-1.29-1.9-2.43-3.64-3.49-5.24a2.31,2.31,0,0,0-.61-.61,2,2,0,0,0-2.73.61c-1,1.59-2.13,3.27-3.49,5.24-6,8.81-15,22.1-15,29.69a20,20,0,0,0,5.92,14.2,20.29,20.29,0,0,0,14.2,5.85,20.22,20.22,0,0,0,20.12-20.12C295.75,143,286.79,129.82,280.79,121Zm6.3,41.08a16.06,16.06,0,0,1-11.47,4.71,16.38,16.38,0,0,1-11.47-4.71,16.06,16.06,0,0,1-4.71-11.47c0-6.38,8.58-19.06,14.28-27.49.68-1,1.29-2,1.9-2.81.61.84,1.21,1.82,1.9,2.81,5.7,8.5,14.28,21.11,14.28,27.49A16.38,16.38,0,0,1,287.09,162.09Z" transform="translate(0 -10)" />
        <path d="M287.4,149.26a2,2,0,0,0-2.05,1.82,9.77,9.77,0,0,1-1.44,4.63,9.23,9.23,0,0,1-3.49,3.34,2,2,0,0,0,2,3.49,13.16,13.16,0,0,0,4.86-4.71,12.92,12.92,0,0,0,2-6.53A2,2,0,0,0,287.4,149.26Z" transform="translate(0 -10)" />
      </g>
    </g>
  </svg>
</SvgIcon>
