import React, { useState, useCallback, useEffect } from 'react';
import { Step, StepContent } from '@material-ui/core';
import { StepProps } from '@material-ui/core/Step';
import { Cube } from '../../types';
import CustomStepLabel from './CustomStepLabel';
import NumericTextField from '../NumericTextField';
import useAuthorized from '../../useAuthorized';
import { ReceiptWithCubeTestAndCubeWithCubeNumbers, CubeWithCubeNumber } from '../../containers/PressureStrength';

export type Step = {
  title: string,
  Component?: React.FC<{ cube: CubeWithCubeNumber, receipt: ReceiptWithCubeTestAndCubeWithCubeNumbers, onChange: (cube: CubeWithCubeNumber) => void }>,
  label?: string,
  icon?: JSX.Element,
  key: keyof Cube,
  endAdornment?: JSX.Element,
  startAdornment?: JSX.Element,
  decimalScale?: number,
  optional?: boolean
}

type CubeEditStepProps = Omit<StepProps, 'onChange'> & {
  step: Step,
  cube: CubeWithCubeNumber,
  receipt: ReceiptWithCubeTestAndCubeWithCubeNumbers,
  onChange: (cube: CubeWithCubeNumber) => void
}

const CubeEditStep: React.FC<CubeEditStepProps> = ({ step, cube, receipt, onChange, ...stepProps }) => {
  const { label, optional, icon, title, Component, key, endAdornment, startAdornment, decimalScale } = step;
  const [active, setActive] = useState(optional ? cube[key] ? true : false : true);
  const value = cube[key];
  const canEdit = useAuthorized(['update:cubes'])

  useEffect(() => {
    setActive(optional ? value ? true : false : true);
  }, [optional, value]);

  const handleChange = useCallback((key: keyof Cube, value: number) => {
    cube[key] = value as never;
    onChange(cube);
  }, [cube, onChange]);

  return (
    <Step {...stepProps} active={active}>
      <CustomStepLabel
        optional={optional}
        icon={icon}
        active={active}
        onClick={() => setActive(!active)}
      >
        {title}
      </CustomStepLabel>
      <StepContent>
        {Component ? <Component cube={cube} receipt={receipt} onChange={onChange} /> : <NumericTextField
          label={label}
          onChange={e => handleChange(key, e.target.value as number)}
          maximumFractionDigits={decimalScale}
          value={value as number}
          disabled={!canEdit}
          InputProps={{
            endAdornment,
            startAdornment
          }}
        />}
      </StepContent>
    </Step>
  )
}

export default CubeEditStep;
