import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

export default () => createMuiTheme({
  palette: {
    type: 'dark',
    secondary: {
      main: lightBlue.A700
    }
  }
});
