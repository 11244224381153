
import React from 'react';
import StepLabel, { StepLabelProps } from '@material-ui/core/StepLabel';
import { StepButton, Checkbox, Typography } from '@material-ui/core';

type CustomStepLabelProps = StepLabelProps & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  active?: boolean
}

const CustomStepLabel: React.FC<CustomStepLabelProps> = (props) => {
  const { children, optional, active, onClick, ...labelProps } = props;
  return optional ? (
    <StepButton
      onClick={onClick}
      style={{ width: 'auto' }}
      icon={labelProps.icon}>
      <div style={{ display: 'flex' }}>
        <Checkbox checked={active} style={{ padding: 0, marginRight: 8 }} />
        <Typography variant="body2" style={{ opacity: 0.7 }}>{children}</Typography>
      </div>
    </StepButton>
  ) : <StepLabel {...labelProps}>{children}</StepLabel>
}

export default CustomStepLabel;
