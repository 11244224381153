import React, { useState, Fragment, useMemo } from 'react'
import { makeStyles, Theme, Divider, List, ListItem, ListItemIcon, Icon, Typography, Drawer, AppBar, Toolbar, IconButton, ListItemText, ListSubheader } from '@material-ui/core'
import moment from 'moment'
import { Cube, CubeType } from '../../types'
import { PressureIcon, PenetrationIcon } from '../../icons'
import { useTheme } from '@material-ui/core'

const drawerWidth = 340

const useCubesScheduledStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  }
}))

const CubesScheduled: React.FC<{ cubesByDay: Array<{ date: Date, cubes: Array<Cube & { cubeNumber: number }> }> }> = ({ cubesByDay }) => {
  const [open, setOpen] = useState(false)
  const { drawer, drawerPaper } = useCubesScheduledStyles()
  const theme: Theme = useTheme()
  const text = useMemo(() => {
    if (cubesByDay.length === 0) {
      return 'Geen geplande druksterktemetingen'
    } else {
      const diffNext = Math.abs(moment(new Date()).diff(cubesByDay[0].date, 'days'))
      const pressure = cubesByDay[0].cubes.filter(cube => cube.type === CubeType.Pressure)
      const penetration = cubesByDay[0].cubes.filter(cube => cube.type === CubeType.Penetration)
      const ripeness = cubesByDay[0].cubes.filter(cube => cube.type === CubeType.Ripeness)
      return (diffNext <= 1 ? 'Morgen' : 'Over ' + diffNext + ' dagen') +
        (pressure.length > 0 ? ' ' + pressure.length + ' druksterkte-' : '') +
        (pressure.length > 0 && (penetration.length > 0 || ripeness.length) ? ' en' : '') +
        (penetration.length > 0 ? ' ' + penetration.length + ' indringings-' : '') +
        (ripeness.length > 0 && (pressure.length > 0 || penetration.length) ? ' en' : '') +
        (ripeness.length > 0 ? ' ' + ripeness.length + ' rijpheids-' : '') +
        (pressure.length > 1 || penetration.length > 1 || ripeness.length > 1 ? ' metingen' : ' meting')
    }
  }, [cubesByDay])

  return (
    <Fragment>
      <Divider />
      <List disablePadding={true} dense={true}>
        <ListItem button={true} onClick={() => setOpen(!open)}>
          <ListItemIcon><Icon>event</Icon></ListItemIcon>
          <ListItemText primary={<Typography variant="caption">{text}</Typography>} />
        </ListItem>
      </List>
      <Drawer
        className={drawer}
        anchor="right"
        open={open}
        classes={{
          paper: drawerPaper,
        }}
        onClose={() => setOpen(false)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <AppBar color="default" position="static">
          <Toolbar>
            <Typography variant="h6">{moment().format('dddd, D MMMM')}</Typography>
            <span style={{ flex: 1 }} />
            <IconButton onClick={() => setOpen(false)}>
              <Icon>close</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ height: '100%', overflow: 'auto' }}>
          {cubesByDay.map(({ date, cubes }, k) => {
            return (
              <List disablePadding={true} key={k} dense={true} subheader={
                <ListSubheader style={{ background: theme.palette.background.paper }}>{moment(date).format('dddd, D MMMM')}</ListSubheader>
              }>
                {cubes.map((cube, k) =>
                  <ListItem key={k}>
                    <ListItemIcon>{cube.type === CubeType.Pressure ? <PressureIcon /> : <PenetrationIcon />}</ListItemIcon>
                    <ListItemText
                      primary={(cube.type ? (cube.type === CubeType.Pressure ? 'Druksterktemeting' : cube.type === CubeType.Penetration ? 'Indringingsmeting' : 'Rijpheidsmeting') : '')}
                      secondary={'Kubusnr.: ' + cube.cubeNumber + ', ' + cube.numberOfDays + (cube.numberOfDays === 1 ? ' dag' : ' dagen')}
                    />
                  </ListItem>
                )}
              </List>
            )
          })}
        </div>
      </Drawer>
    </Fragment>
  )
}

export default CubesScheduled
