import React, { useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';

const CancelCubeDialog: React.FC<DialogProps & { onCancel?: () => void, onConfirm?: (cancelRemarks: string) => void }> = ({ open, onCancel, onConfirm, ...dialogProps }) => {
  const [cancelRemarks, setCancelRemarks] = useState('');
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="xs"
      {...dialogProps}
      onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}
    >
      <DialogTitle>Meting vervallen</DialogTitle>
      <DialogContent style={{ paddingTop: 8 }}>
        <TextField
          label="Waarom is de meting vervallen?"
          multiline={true}
          rows={5}
          fullWidth={true}
          variant="outlined"
          onChange={(e) => setCancelRemarks(e.target.value)}
          value={cancelRemarks}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Annuleren</Button>
        {onConfirm && <Button disabled={cancelRemarks.length < 1} onClick={() => onConfirm(cancelRemarks)} color="secondary">Verwijderen</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default CancelCubeDialog;
