import { Button, Icon, List, ListItem, ListItemText, Popover, TextField } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { StaticDateRangePicker, DateRangeDelimiter, DateRange } from '@material-ui/pickers'

const DateFilter: React.FC<{ startDate: Date, endDate: Date, onChange: (range: { startDate: Date, endDate: Date }) => void }> = ({ startDate, endDate, onChange }) => {
  const [datePickerAnchor, setDatePickerAnchor] = useState(null as null | (EventTarget & HTMLButtonElement))
  const [value, setValue] = useState<DateRange<Date> | undefined>(undefined)

  const handleChange = useCallback(([s, e]: DateRange<moment.Moment>) => {
    setDatePickerAnchor(null)
    onChange({ startDate: s?.toDate() || startDate, endDate: e?.toDate() || endDate })
  }, [onChange, startDate, endDate])

  return <List>
    <ListItem>
      <ListItemText primary="Rapportage periode" />
    </ListItem>
    <ListItem>
      <Button
        size="large"
        variant="outlined"
        fullWidth={true}
        onClick={e => setDatePickerAnchor(e.currentTarget)}
        style={{ display: 'flex', textTransform: 'none', justifyContent: 'space-between' }}
      >
        <span>{moment(startDate).format('D MMM YYYY')}</span>
        <Icon>trending_flat</Icon>
        <span>{moment(endDate).format('D MMM YYYY')}</span>
      </Button>
      <Popover
        onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}
        open={Boolean(datePickerAnchor)}
        anchorEl={datePickerAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setDatePickerAnchor(null)}
      >
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          disableCloseOnSelect={false}
          open={true}
          disableFuture={true}
          value={value || [startDate, endDate]}
          onChange={newValue => setValue(newValue)}
          onAccept={(range: any) => handleChange(range)}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <DateRangeDelimiter translate> to </DateRangeDelimiter>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </Popover>
    </ListItem>
  </List>
}

export default DateFilter
