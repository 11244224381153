import React, { Fragment } from 'react';
import { List, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ListProps } from '@material-ui/core/List';

const useStyles = makeStyles({
  listContainer: {
    width: 340,
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column'
  }
});

const CubeTestsList: React.FC<ListProps> = ({ children, ...listProps }) => {
  const { listContainer } = useStyles();
  return (
    <Fragment>
      <Divider />
      <Typography variant="subtitle1" style={{ lineHeight: '48px', paddingLeft: 16, paddingRight: 16 }}>Monsternames vandaag</Typography>
      <List {...listProps} disablePadding={true} className={listContainer}>
        {children}
      </List>
    </Fragment>
  )
}

export default CubeTestsList;
