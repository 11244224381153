import * as React from 'react';
import {Frame} from 'framer';

function Close() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg" width="64" height="64"><g><path d="M 0 32 C 0 14.327 14.327 0 32 0 C 49.673 0 64 14.327 64 32 C 64 49.673 49.673 64 32 64 C 14.327 64 0 49.673 0 32 Z" fill="rgb(235,66,63)"></path><path d="M 37.657 32 L 46.828 22.829 C 48.391 21.266 48.391 18.734 46.828 17.171 C 45.266 15.609 42.734 15.609 41.171 17.172 L 32 26.343 L 22.829 17.172 C 21.266 15.609 18.734 15.609 17.171 17.172 C 15.609 18.734 15.609 21.266 17.172 22.829 L 26.343 32 L 17.172 41.171 C 15.609 42.734 15.609 45.266 17.172 46.829 C 18.734 48.391 21.266 48.391 22.829 46.828 L 32 37.657 L 41.171 46.828 C 42.734 48.391 45.266 48.391 46.829 46.828 C 48.391 45.266 48.391 42.734 46.828 41.171 Z" fill="rgb(233,238,242)"></path></g></svg>
  );
}

function Cubes() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg" width="224" height="168"><g><path d="M 132 56 L 196 56 L 196 112 L 132 112 Z" fill="rgb(233,238,242)"></path><path d="M 212 96 L 196 112 L 196 56 L 212 40 Z" fill="rgb(182,185,186)"></path><path d="M 196 56 L 132 56 L 148 40 L 212 40 Z" fill="rgb(225,227,230)"></path><path d="M 80 124 L 64 140 L 64 84 L 80 68 Z" fill="rgb(182,185,186)"></path><path d="M 0 84 L 64 84 L 64 140 L 0 140 Z" fill="rgb(225,227,230)"></path><path d="M 64 84 L 0 84 L 16 68 L 80 68 Z" fill="rgb(208,210,212)"></path><path d="M 87.979 68.003 L 131.972 24.01 L 171.966 64.004 L 127.973 107.997 Z" fill="rgb(225,227,230)"></path><path d="M 172 40 L 172 64 L 132 24 L 132 0 Z" fill="rgb(182,185,186)"></path><path d="M 132 24 L 88 68 L 88 44 L 132 0 Z M 64 112 L 128 112 L 128 168 L 64 168 Z" fill="rgb(233,238,242)"></path><path d="M 144 152 L 128 168 L 128 112 L 144 96 Z" fill="rgb(182,185,186)"></path><path d="M 128 112 L 64 112 L 80 96 L 144 96 Z" fill="rgb(208,210,212)"></path><path d="M 144 92 L 208 92 L 208 148 L 144 148 Z" fill="rgb(233,238,242)"></path><path d="M 224 132 L 208 148 L 208 92 L 224 76 Z" fill="rgb(182,185,186)"></path><path d="M 208 92 L 144 92 L 160 76 L 224 76 Z" fill="rgb(208,210,212)"></path></g></svg>
  );
}

export default (props: any) => {
  return (
    <Frame {...props} style={{
      top: props.style.height / 6
    }} background="none">
      <Frame background="none" initial={{y:-100}} animate={{y: 0}} transition={{duration: 0.4, easing: "linear"}} style={{
        position:  "absolute",
        left: 16,
        top: 44,
        width: 224, height: 168
      }}>
        <Cubes />
      </Frame>
      <Frame background="none" style={{
        position:  "absolute",
        width: 64, height: 64,
        left: 56,
        top: 64
      }} animate={{rotate: 360}} transition={{duration: 2, yoyo: Infinity, easing: "easeInOut"}}>
        <Close/>
      </Frame>
    </Frame>
  );
}
