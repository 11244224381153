import React, { useCallback, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { TextField, Paper, Theme, makeStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

const InputComponent = ({ label, value, fullWidth, margin, variant, ...inputProps }: any) => {
  return (
    <TextField
      label={label}
      value={value}
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      inputProps={inputProps}
    />
  );
}

const SuggestionsContainer = ({ containerProps, children, query }: any) => {
  return (
    <Paper {...containerProps} square={true}>
      {children}
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: -8,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }
}));

type AutocompleteProps = {
  getSuggestions: (value: string) => any[],
  renderSuggestion: (suggestion: any, params: Autosuggest.RenderSuggestionParams) => JSX.Element,
  getSuggestionValue: (suggestion: any) => string
  onChange: (suggestion: any) => void
} & Omit<TextFieldProps, 'onChange'>

const Autocomplete: React.FC<AutocompleteProps> = ({ getSuggestions, getSuggestionValue, renderSuggestion, ...props }) => {
  const [value, setValue] = useState(props.value || '');
  const [filtered, setFiltered] = useState([] as any);
  const theme = useStyles();

  const onChange = useCallback((event, { newValue }) => setValue(newValue), []);

  const onSuggestionsFetchRequested = useCallback(({ value }) => setFiltered(getSuggestions(value)), [getSuggestions]);

  const onSuggestionsClearRequested = useCallback(() => setFiltered([]), []);

  const handleSuggestionSelected = useCallback((event, { suggestion }) => props.onChange(suggestion), [props]);

  const inputProps = {
    ...props as any,
    value,
    onChange
  };

  return <Autosuggest
    id="react-autosuggest-simple"
    suggestions={filtered}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onSuggestionsClearRequested}
    onSuggestionSelected={handleSuggestionSelected}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion}
    inputProps={inputProps}
    renderInputComponent={InputComponent}
    renderSuggestionsContainer={SuggestionsContainer}
    theme={theme}
  />
}

export default Autocomplete;
