import React, { Fragment, useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { Cube } from '../../types'
import { InputAdornment, Typography } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import NumericTextField from '../NumericTextField'
import { ReceiptWithCubeTestAndCubeWithCubeNumbers } from '../../containers/PressureStrength'

const pressureStrengthFormatProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 1,
  displayType: 'text' as 'text'
}

type CubePressureStepProps = {
  cube: Cube
  receipt: ReceiptWithCubeTestAndCubeWithCubeNumbers
  onChange: (cube: Cube) => void
}

const CubePressureStep: React.FC<CubePressureStepProps> = ({ cube, receipt, onChange }) => {
  const { pressureStrength, numberOfDays, length, width } = cube
  const [value, setValue] = useState(null as number | null)
  const hasError = useMemo(() => {
    if (pressureStrength && receipt.revision.recipe.strengthClass) {
      if (pressureStrength < receipt.revision.recipe.strengthClass.cubePressureStrength - 4) {
        return true
      }
    }
    return false
  }, [receipt.revision.recipe.strengthClass, pressureStrength])

  useEffect(() => {
    if (pressureStrength && (length || width)) {
      const square = (length || 150) * (width || 150)
      setValue(pressureStrength * (square / 1000))
    } else if (pressureStrength) {
      setValue(pressureStrength)
    } else {
      setValue(null)
    }
  }, [pressureStrength, length, width, setValue])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const square = (length || 150) * (width || 150)
    setValue(Number(value))
    onChange({ ...cube, pressureStrength: (length || width) ? Number(value) / (square / 1000) : Number(value) })
  }, [cube, length, width, onChange, setValue])

  return (
    <Fragment>
      <NumericTextField
        label={'Drukproef na ' + numberOfDays + (numberOfDays === 1 ? ' dag' : ' dagen')}
        onChange={handleChange as any}
        value={value as number}
        InputProps={{
          endAdornment: (width || length) ? <InputAdornment position="end">kN</InputAdornment> : <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>,
        }}
        error={hasError}
        {...(width || length) ? { helperText: <Typography variant="caption" color={hasError ? 'error' : 'secondary'}>Berekende druksterkte: <NumberFormat value={pressureStrength || ''} {...pressureStrengthFormatProps} /> N/mm<sup>2</sup></Typography> } : null}
      />
    </Fragment>
  )
}

export default CubePressureStep
