import React from 'react'
import { Typography, makeStyles, Box } from '@material-ui/core'

type ErrorViewProps = {
  statusCode: string
  icon: React.ReactNode
  title: string
}

const useStyles = makeStyles(theme => ({
  largeNumber: {
    fontSize: 256,
    marginTop: 128,
    lineHeight: 0.72,
    fontWeight: 600,
    color: theme.palette.grey[300]
  },
  content: {
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
    background: theme.palette.secondary.dark
  }
}))

const ErrorView: React.FC<ErrorViewProps> = ({ statusCode, icon, title, children }) => {
  const { content, largeNumber } = useStyles()
  return <Box display="flex" flexDirection="column" flex={1}>
    <Box position="relative" display="flex" justifyContent="center">
      <Typography className={largeNumber}>{statusCode}</Typography>
      <Box position="absolute" bottom={-60} display="flex">{icon}</Box>
    </Box>
    <Box flex={1} className={content} display="flex" flexDirection="column" alignItems="center" padding={4} paddingTop={10}>
      <Typography variant="h2">{title}</Typography>
      {children}
    </Box>
  </Box>
}

export default ErrorView
