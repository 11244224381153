import React, { useCallback, ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { Cube } from '../../types';
import useAuthorized from '../../useAuthorized';

type CubeRemarksStepProps = {
  cube: Cube
  onChange: (cube: Cube) => void
}

const CubeRemarksStep: React.FC<CubeRemarksStepProps> = (props) => {
  const { cube, onChange } = props;
  const { remarks } = cube;
  const canEdit = useAuthorized(['update:cubes'])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    cube.remarks = e.target.value;
    onChange(cube);
  }, [cube, onChange]);

  return (
    <TextField
      label="Opmerkingen"
      multiline={true}
      value={remarks || ''}
      onChange={handleChange}
      variant="outlined"
      margin="normal"
      rows={3}
      fullWidth={true}
      style={{ maxWidth: 500 }}
      disabled={!canEdit}
    />
  )
}

export default CubeRemarksStep;
