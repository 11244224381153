import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => <SvgIcon {...props}>
  <svg viewBox="0 0 33 33">
        <path d="M10.577,32.998c-0.133,0-0.26-0.053-0.354-0.146L0.146,22.773c-0.195-0.195-0.195-0.512,0-0.707L22.07,0.143
		c0.188-0.188,0.52-0.188,0.707,0l10.076,10.078c0.195,0.195,0.195,0.512,0,0.707L10.93,32.852
		C10.836,32.945,10.709,32.998,10.577,32.998z M1.207,22.42l9.37,9.371l21.216-21.217l-9.369-9.371L1.207,22.42z" />
        <g>
          <path d="M21.144,7.207c-0.128,0-0.256-0.049-0.354-0.146l-2.467-2.467c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			l2.467,2.467c0.195,0.195,0.195,0.512,0,0.707C21.4,7.158,21.272,7.207,21.144,7.207z" />
          <path d="M19.119,12.396c-0.128,0-0.256-0.049-0.354-0.146l-4.049-4.049c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			l4.049,4.049c0.195,0.195,0.195,0.512,0,0.707C19.375,12.348,19.247,12.396,19.119,12.396z" />
          <path d="M13.928,14.424c-0.128,0-0.256-0.049-0.354-0.146l-2.467-2.467c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			l2.467,2.467c0.195,0.195,0.195,0.512,0,0.707C14.184,14.375,14.056,14.424,13.928,14.424z" />
          <path d="M11.901,19.615c-0.128,0-0.256-0.049-0.354-0.146L7.498,15.42c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			l4.049,4.049c0.195,0.195,0.195,0.512,0,0.707C12.157,19.566,12.029,19.615,11.901,19.615z" />
          <path d="M6.711,21.64c-0.128,0-0.256-0.049-0.354-0.146l-2.467-2.467c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			l2.467,2.467c0.195,0.195,0.195,0.512,0,0.707C6.967,21.591,6.839,21.64,6.711,21.64z" />
        </g>
      </svg>
</SvgIcon>
