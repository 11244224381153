import React from 'react'
import ErrorView from '../views/ErrorView'
import { Typography, Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import SearchCubeIconFrame from '../icons/SearchCubeIconFrame';



const style = {width: 200, height: 200};
const errorViewProps = {
  title: 'Pagina niet gevonden',
  icon: <div style={style}>
    <SearchCubeIconFrame style={style} />
  </div>,
  statusCode: '404'
}

const NotFound: React.FC = () => {
  const history = useHistory()
  return (
    <ErrorView {...errorViewProps}>
      <Typography gutterBottom={true}>Waarom probeert u in plaats daarvan niet een van deze pagina's?</Typography>
      <Box display="flex" alignItems="center" marginTop={2}>
        <Button onClick={() => history.push('/sample')} variant="contained" color="secondary" size="large">Monstername</Button>&nbsp;
        	<Button onClick={() => history.push('/pressure')} variant="contained" color="secondary" size="large">Druksterkte</Button>&nbsp;
        	<Button onClick={() => history.push('/reports')} variant="contained" color="secondary" size="large">Rapportage</Button>
      </Box>
    </ErrorView>
  )
}

export default NotFound
