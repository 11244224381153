import React, { useState, useEffect, useCallback } from 'react'
import { CubeTest } from '../../types'
import Step, { StepProps } from '@material-ui/core/Step'
import CustomStepLabel from '../CustomStepLabel'
import { StepContent } from '@material-ui/core'
import NumericTextField from '../NumericTextField'
import useAuthorized from '../../useAuthorized'
import { ReceiptWithCubeTest } from '../../containers/Sampling'

export type Step = {
  title: string,
  optional?: boolean,
  Component?: React.FC<{ receipt: ReceiptWithCubeTest, onChange: (receipt: ReceiptWithCubeTest) => void }>,
  label?: string,
  icon?: JSX.Element,
  key: keyof CubeTest,
  isActive?: (receipt: ReceiptWithCubeTest) => boolean,
  endAdornment?: JSX.Element,
  startAdornment?: JSX.Element,
  decimalScale?: number,
  required?: boolean
}

type EditStepProps = Omit<StepProps, 'onChange'> & {
  step: Step
  receipt: ReceiptWithCubeTest
  onChange: (receipt: ReceiptWithCubeTest, skipSave?: boolean) => void
}

const CubeTestEditStep: React.FC<EditStepProps> = ({ step, receipt, onChange, ...stepProps }) => {
  const { label, optional, icon, title, Component, key, required, endAdornment, startAdornment, decimalScale } = step
  const [active, setActive] = useState(optional && receipt.cubeTest ? receipt.cubeTest[key] ? true : false : true)
  const value = receipt.cubeTest && receipt.cubeTest[key]
  const canEdit = useAuthorized(['update:cubetests'])

  useEffect(() => {
    setActive(optional ? value ? true : false : true)
  }, [optional, value])

  const handleChange = useCallback((key: keyof CubeTest, value: string | number) => {
    receipt.cubeTest[key] = value as never
    onChange(receipt)
  }, [onChange, receipt])

  return (<Step {...stepProps} active={active}>
    <CustomStepLabel
      optional={optional}
      icon={icon}
      active={active}
      onClick={() => setActive(!active)}
    >
      {title}
    </CustomStepLabel>
    <StepContent id={key}>
      {Component ? <Component receipt={receipt} onChange={onChange} /> : <NumericTextField
        label={label}
        onChange={e => handleChange(key as keyof CubeTest, e.target.value as number)}
        value={typeof value !== 'undefined' ? value as number : ''}
        error={required && !value}
        required={required}
        maximumFractionDigits={decimalScale}
        disabled={!canEdit}
        InputProps={{
          endAdornment,
          startAdornment
        }}
      />}
    </StepContent>
  </Step>)
}

export default CubeTestEditStep
