import React from 'react';

import { Grid, CircularProgress, Typography } from '@material-ui/core';

const Loading: React.FC<{ loadingText?: string }> = ({ loadingText }) => {
  return (
    <Grid container={true} direction="column" justify="center" alignContent="center" style={{ height: '100%' }}>
      <CircularProgress variant="indeterminate" size={120} />
      {loadingText && <Typography>{loadingText}</Typography>}
    </Grid>
  );
}

export default Loading;
