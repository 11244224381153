import React, { ChangeEvent, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import useAuthorized from '../../useAuthorized'
import { CubeTest } from '../../types'
import { ReceiptWithCubeTest } from '../../containers/Sampling'

const CubeTestRemarksStep: React.FC<{ receipt: ReceiptWithCubeTest, onChange: (receipt: ReceiptWithCubeTest) => void }> = ({ receipt, onChange }) => {
  const { remarks } = receipt.cubeTest as CubeTest
  const canEdit = useAuthorized(['update:cubetests'])

  const handleChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, remarks: e.target.value } })
  }, [receipt, onChange])

  return (
    <TextField
      label="Opmerkingen"
      multiline={true}
      value={remarks || ''}
      onChange={handleChange}
      variant="outlined"
      margin="normal"
      rows={3}
      fullWidth={true}
      style={{ maxWidth: 500 }}
      disabled={!canEdit}
    />
  )
}

export default CubeTestRemarksStep
