import * as React from 'react';
import {Frame} from 'framer';

function SearchGlass() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200"><g transform="translate(-0.61 0.196)"><path d="M 88.018 120.246 L 71.647 136.799 C 70.477 137.856 68.957 138.443 67.553 138.443 C 66.033 138.443 64.514 137.856 63.344 136.799 C 61.122 134.451 61.122 130.695 63.344 128.464 L 79.833 112.027 C 82.054 109.679 85.796 109.679 88.018 112.027 C 90.357 114.258 90.357 118.016 88.018 120.246 Z M 88.018 120.246" fill="rgb(70,59,133)"></path><path d="M 88.018 120.246 L 71.647 136.799 C 70.477 137.856 68.957 138.443 67.553 138.443 C 66.033 138.443 64.514 137.856 63.344 136.799 L 88.018 112.027 C 90.357 114.258 90.357 118.016 88.018 120.246 Z M 88.018 120.246" fill="rgb(43,34,86)"></path><path d="M 71.647 128.464 C 64.864 121.537 53.638 121.537 46.855 128.464 L 5.175 170.308 C -1.725 177.115 -1.725 188.386 5.175 195.196 C 12.076 202.122 23.066 202.122 29.966 195.196 L 71.647 153.353 C 78.547 146.543 78.547 135.272 71.647 128.464 Z M 71.647 128.464" fill="rgb(118,226,248)"></path><path d="M 71.647 128.464 C 78.547 135.274 78.547 146.543 71.647 153.353 L 29.966 195.196 C 23.066 202.122 12.076 202.122 5.175 195.196 Z M 71.647 128.464" fill="rgb(0,192,241)"></path><path d="M 178.999 120.246 C 151.634 147.835 107.313 147.835 79.833 120.246 C 52.352 92.774 52.352 48.279 79.833 20.691 C 107.196 -6.897 151.518 -6.897 178.999 20.691 C 206.48 48.162 206.48 92.658 178.999 120.246 Z M 170.696 29.027 C 147.892 6.017 110.939 6.017 88.135 29.027 C 65.214 51.92 65.214 89.018 88.135 111.911 C 110.938 134.921 147.892 134.921 170.696 111.911 C 193.616 89.018 193.616 51.92 170.696 29.027 Z" fill="rgb(84,70,157)"></path></g></svg>
  )
}

function Cube() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72"><path d="M 67.177 18 C 66.89 17.502 66.476 17.089 65.979 16.802 L 37.636 0.439 C 36.624 -0.146 35.376 -0.146 34.364 0.439 L 6.022 16.802 C 5.524 17.089 5.111 17.502 4.824 18 L 36 36 Z" fill="rgb(0,215,223)"></path><path d="M 36 72 C 36.575 72 37.139 71.849 37.636 71.561 L 65.979 55.198 C 66.991 54.613 67.615 53.533 67.615 52.364 L 67.615 19.636 C 67.615 19.052 67.459 18.489 67.176 18 L 36 36 Z" fill="rgb(82,134,250)"></path><path d="M 34.364 71.561 C 34.861 71.849 35.426 72 36 72 L 36 36 L 4.823 18 C 4.536 18.497 4.385 19.062 4.385 19.636 L 4.385 52.364 C 4.385 53.533 5.009 54.613 6.021 55.198 Z" fill="rgb(66,78,222)"></path></svg>
  )
}

function QuestionMark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"><path d="M 22.5 0 C 10.065 0 0 10.063 0 22.5 C 0 34.935 10.063 45 22.5 45 C 34.935 45 45 34.937 45 22.5 C 45 10.065 34.937 0 22.5 0 Z M 22.5 41.484 C 12.008 41.484 3.516 32.994 3.516 22.5 C 3.516 12.008 12.006 3.516 22.5 3.516 C 32.992 3.516 41.484 12.006 41.484 22.5 C 41.484 32.992 32.994 41.484 22.5 41.484 Z M 29.531 18.325 C 29.531 21.596 27.287 24.352 24.258 25.134 L 24.258 27.993 C 24.258 28.964 23.471 29.751 22.5 29.751 C 21.529 29.751 20.742 28.964 20.742 27.993 L 20.742 23.599 C 20.742 22.628 21.529 21.841 22.5 21.841 C 24.439 21.841 26.016 20.264 26.016 18.325 C 26.016 16.387 24.439 14.81 22.5 14.81 C 20.561 14.81 18.984 16.387 18.984 18.325 C 18.984 19.296 18.197 20.083 17.227 20.083 C 16.256 20.083 15.469 19.296 15.469 18.325 C 15.469 14.448 18.623 11.294 22.5 11.294 C 26.377 11.294 29.531 14.448 29.531 18.325 Z M 24.697 33.267 C 24.697 34.48 23.714 35.464 22.5 35.464 C 21.286 35.464 20.303 34.48 20.303 33.267 C 20.303 32.053 21.286 31.069 22.5 31.069 C 23.714 31.069 24.697 32.053 24.697 33.267 Z" fill="hsl(0, 0%, 100%)"></path></svg>
  )
}

export default (props: any) => {
  const durationAndDelay = 1.4;
  return (
    <Frame {...props} background="none">

      <Frame animate={{
        x: [-100, 0, 100, 0, 0, 0],
        y: [0,0,0,-50, 50, 0]

      }} transition={{duration: durationAndDelay, easing: "easeIn"}} background="none">
          <SearchGlass />
      </Frame>
      <Frame initial={{scale: 0.2}} style={{position: "absolute", top: 35, left: 93, width: 72, height: 72 }} animate={{
        scale: [0, 0.2, 0.4, 1, 0.3, 0.4, 0.6, 0.8, 1]
      }} transition={{delay: durationAndDelay}} background="none">
        <Cube />
      </Frame>
      <Frame  background="none" initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: durationAndDelay + 1}}
      style={{position: "absolute", top: 48, left: 106, width: 45, height: 45 }}>
        <QuestionMark />
      </Frame>
    </Frame>
  )
}
