import jwt from 'jsonwebtoken'
import { LOCAL_STORAGE_KEY } from '.'

let decoded: { permissions: string[], exp: number }

export default function (permissions?: string[]) {
  if (decoded && decoded.exp < Date.now()) {
    decoded = null as any
  }
  if (!decoded) {
    const oldToken = localStorage.getItem(LOCAL_STORAGE_KEY)
    decoded = jwt.decode(oldToken || '') as typeof decoded
    if (!decoded) {
      return false
    }
  }
  let authorized = false
  permissions && permissions.some(p => {
    if (decoded.permissions.indexOf(p) >= 0) {
      authorized = true
      return true
    }
    return false
  })
  return authorized
}
