import React from 'react';
import { createStyles, Theme, WithStyles, withStyles, Typography, IconButton, Icon } from '@material-ui/core';
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@material-ui/core/DialogTitle';

const dialogTitleStyles = createStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    paddingRight: 64
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))

type DialogTitleProps = {
  subTitle?: string,
  onClose?: () => void,
  classes: { root?: string, closeButton?: string }
} & WithStyles<typeof dialogTitleStyles> & MuiDialogTitleProps;

const DialogTitle = withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
  const { children, subTitle, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography={true} className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
