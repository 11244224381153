import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default (props: SvgIconProps) => <SvgIcon {...props}>
  <svg viewBox="0 -30 512.001 512">
    <g>
      <path d="m332 241.703125c0 5.523437-4.476562 10-10 10s-10-4.476563-10-10 4.476562-10 10-10 10 4.476563 10 10zm0 0" />
      <path d="m141.980469 144.027344 65.546875 131.085937c5.113281 10.234375 15.394531 16.589844 26.832031 16.589844h175.28125c11.4375 0 21.71875-6.355469 26.832031-16.589844l65.546875-131.089843c6.558594-5.890626 9.980469-13.9375 9.980469-22.320313 0-16.542969-13.457031-30-30-30h-15.855469l-62.933593-62.929687c-5.664063-5.667969-13.199219-8.789063-21.210938-8.789063s-15.546875 3.121094-21.210938 8.789063l-18.789062 18.789062-38.789062-38.789062c-11.695313-11.695313-30.726563-11.695313-42.421876 0l-82.933593 82.929687h-15.855469c-16.574219 0-30 13.425781-30 30 0 8.507813 3.695312 16.667969 9.980469 22.324219zm276.605469 122.144531c-1.707032 3.410156-5.132813 5.53125-8.945313 5.53125h-175.28125c-3.8125 0-7.238281-2.121094-8.945313-5.53125l-57.234374-114.46875h307.640624zm-43.65625-223.257813c1.890624-1.886718 4.398437-2.929687 7.070312-2.929687s5.179688 1.042969 7.070312 2.929687l48.789063 48.789063h-51.714844l-30-30zm-100-20c3.898437-3.898437 10.242187-3.898437 14.140624 0l68.789063 68.789063h-151.714844zm-112.929688 88.789063h320c5.515625 0 10 4.484375 10 10 0 5.519531-4.476562 10-10 10h-320c-5.421875 0-10-4.367187-10-10 0-5.519531 4.476562-10 10-10zm0 0" />
      <path d="m10 111.703125h71.078125l107.257813 214.472656c.574218 1.148438 1.363281 2.136719 2.285156 2.964844l-12.363282 74.171875c-4.21875 25.335938 15.332032 48.390625 40.992188 48.390625 17.90625 0 33.75-11.410156 39.425781-28.417969l30.53125-91.582031h70.515625c2.253906 0 4.472656.773437 6.238282 2.179687l15.644531 12.46875c-6.234375 10.671876-9.605469 22.917969-9.605469 35.351563 0 38.597656 31.402344 70 70 70s70-31.402344 70-70-31.402344-70-70-70c-18.039062 0-35.023438 6.839844-47.929688 19.007813l-15.648437-12.46875c-5.292969-4.21875-11.933594-6.539063-18.699219-6.539063-17.152344 0-138.023437 0-156.261718 0l-107.257813-214.472656c-1.691406-3.386719-5.15625-5.527344-8.941406-5.527344h-77.261719c-5.523438 0-10 4.476563-10 10s4.476562 10 10 10zm432 220c27.570312 0 50 22.429687 50 50s-22.429688 50-50 50-50-22.429687-50-50c0-8.042969 1.867188-15.75 5.445312-22.726563l16.214844 12.921876c-1.085937 3.132812-1.660156 6.449218-1.660156 9.804687 0 16.542969 13.460938 30 30 30 16.542969 0 30-13.457031 30-30s-13.457031-30-30-30c-5.71875 0-11.195312 1.601563-15.890625 4.542969l-16.179687-12.894532c8.917968-7.476562 20.171874-11.648437 32.070312-11.648437zm0 40c5.515625 0 10 4.484375 10 10 0 5.511719-4.484375 10-10 10s-10-4.488281-10-10c0-5.457031 4.4375-10 10-10zm-202.296875 45.253906c-2.949219 8.832031-11.164063 14.746094-20.453125 14.746094-13.324219 0-23.449219-11.976563-21.265625-25.105469l12.488281-74.894531h57.652344zm0 0" />
      <path d="m431.753906 172.757812c-4.941406-2.46875-10.949218-.46875-13.417968 4.472657l-27.234376 54.472656h-29.101562c-5.523438 0-10 4.476563-10 10s4.476562 10 10 10h35.28125c3.785156 0 7.25-2.140625 8.941406-5.527344l30-60c2.472656-4.941406.46875-10.945312-4.46875-13.417969zm0 0" />
    </g>
  </svg>
</SvgIcon>
