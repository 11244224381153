import { Button, Dialog, DialogActions, Slide, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import React, { useMemo } from 'react'
import NumberFormat from 'react-number-format'
import { Receipt, StrengthClass } from '../../types'
import DialogTitle from '../DialogTitle'

export const calculateSamples = (volume: number, strengthClass: StrengthClass) => {
  const samples = Math.ceil(volume / strengthClass.samplePerVolume)
  return samples < strengthClass.sampleMinimum ? strengthClass.sampleMinimum : samples > strengthClass.sampleMaximum ? strengthClass.sampleMaximum : samples
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
})

type Volume = {
  strengthClass: StrengthClass,
  wbf: number,
  volume: number,
  numSamples: number
}

type SamplesTodayDialogProps = {
  receipts: Receipt[],
  open: boolean,
  onClose: () => void
}

const SamplesTodayDialog: React.FC<SamplesTodayDialogProps> = ({ receipts, open, onClose }) => {
  const volumes: Volume[] = useMemo(() => receipts ? calculateVolumes(receipts) : [], [receipts])

  return <Dialog open={open} TransitionComponent={Transition} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
    <DialogTitle onClose={onClose}>Overzicht verplichte monsternames</DialogTitle>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Sterkteklasse</TableCell>
          <TableCell align="right">Max. wbf</TableCell>
          <TableCell align="right">Aantal kuub</TableCell>
          <TableCell align="right">Aantal monsternames</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {volumes && volumes.map(({ volume, strengthClass, wbf, numSamples }, k) => {
          return (
            <TableRow key={k}>
              <TableCell>{strengthClass.code}</TableCell>
              <TableCell align="right"><NumberFormat value={wbf} decimalScale={3} decimalSeparator="," thousandSeparator="." displayType="text" /></TableCell>
              <TableCell align="right">{volume}m<sup>3</sup></TableCell>
              <TableCell align="right">{numSamples}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={2}>Totaal</TableCell>
          <TableCell align="right">{volumes && volumes.reduce((total, { volume }) => total += volume, 0)}m<sup>3</sup></TableCell>
          <TableCell align="right">{volumes && volumes.reduce((total, { numSamples }) => total += numSamples, 0)}</TableCell>
        </TableRow>
      </TableFooter>
    </Table>
    <DialogActions>
      <Button onClick={onClose} color="secondary">Beginnen</Button>
    </DialogActions>
  </Dialog>
}

export default SamplesTodayDialog

function calculateVolumes(receipts: Receipt[]) {
  return receipts.reduce((volumes, { revision, amount }) => {
    if (revision.recipe.environmentClasses && revision.recipe.strengthClass) {
      const lowestMaxWbf = revision.recipe.environmentClasses.reduce((lowest, ec) => ec.maxWbf < lowest ? ec.maxWbf : lowest, 1)
      const index = volumes.findIndex(v => revision.recipe.strengthClass && v.strengthClass.id === revision.recipe.strengthClass.id && v.wbf === lowestMaxWbf)
      if (index >= 0) {
        volumes[index].volume += amount
      } else {
        volumes.push({
          strengthClass: revision.recipe.strengthClass,
          wbf: lowestMaxWbf,
          volume: amount,
          numSamples: 0
        })
      }
    }
    return volumes.map(v => {
      const { sampleMaximum, sampleMinimum, samplePerVolume } = v.strengthClass
      let numSamples = Math.ceil(v.volume / samplePerVolume)
      numSamples = numSamples < sampleMinimum ? sampleMinimum : numSamples > sampleMaximum ? sampleMaximum : numSamples
      return { ...v, numSamples }
    })
  }, [] as Volume[])
}
