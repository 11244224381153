import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

type EmptyStateProps = {
  Icon: (props: SvgIconProps) => JSX.Element
  text: string
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { Icon, text } = props;

  return (
    <Grid style={{ height: '100%', flex: 1 }} container={true} direction="column" justify="center" alignItems="center">
      <Icon fontSize="large" style={{ color: '#fff', fontSize: 140, marginBottom: 16 }} />
      <Typography>{text}</Typography>
    </Grid>
  )
}

export default EmptyState;
